import React from 'react'
import * as PaymentMethods from '../../../../constants/paymentMethods'
import { PaymentMethodImageWrapper, PaymentMethodSpanWrapper, PaymentMethodWrapper } from './PaymentMethod.style'

import { ICMChannel } from '@typesApp/cmsPlacement/CMChannel'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'
import { ICMExternalLink } from '@typesApp/cmsPlacement/CMExternalLink'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'
import { ICMExternalProduct } from '@typesApp/cmsPlacement/CMExternalProduct'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { PaymentMethodPositions } from '../../../../types/paymentMethods'
import { getIconByFooterName } from '../../../../utils/payMethods'
interface PaymentMethodsProps {
  availablePaymentMethods: PaymentMethods.FooterName[]
  checkoutFooter?: IPlacement
  copyright?: IPlacement
  children?: React.ReactNode
  position?: PaymentMethodPositions
}
export type ICheckoutPlacementItem =
  | ICMChannel
  | ICMExternalPage
  | ICMExternalChannel
  | ICMExternalLink
  | ICMExternalProduct

const PaymentMethodsIcons: React.FC<PaymentMethodsProps> = ({ availablePaymentMethods }) => (
  <>
    {availablePaymentMethods?.map((paymentMethodName, i) => {
      const PaymentMethodIcon = getIconByFooterName(paymentMethodName)

      return PaymentMethodIcon ? <PaymentMethodIcon key={i} /> : null
    })}
  </>
)

const PaymentMethod: React.FC<PaymentMethodsProps> = ({ availablePaymentMethods, position = 'left', children }) => {
  return (
    <PaymentMethodWrapper position={position}>
      {children ? <PaymentMethodSpanWrapper>{children}</PaymentMethodSpanWrapper> : null}
      <PaymentMethodImageWrapper>
        <PaymentMethodsIcons availablePaymentMethods={availablePaymentMethods as PaymentMethods.DisplayName[]} />
      </PaymentMethodImageWrapper>
    </PaymentMethodWrapper>
  )
}

export default PaymentMethod
