import { useEffect, useCallback } from 'react'
import { guestStatusSelector, canGuestUserCheckoutSelector } from '@features/user/selector'
import { useSelector, useDispatch } from 'react-redux'
import { orderItemsSelector } from '@features/order/selector'
import { setCanGuestUserCheckout } from '@features/user/slice'
import { isGuestCheckoutEnabledSelector } from '@redux/selectors/site'

export const useGuestUserSession = () => {
  const dispatch = useDispatch()

  const userIsGuest = useSelector(guestStatusSelector)
  const orderItems = useSelector(orderItemsSelector)
  const canGuestUserCheckout = useSelector(canGuestUserCheckoutSelector)
  const isGuestCheckoutEnabled = useSelector(isGuestCheckoutEnabledSelector)

  const handleGuestCheckoutStatus = useCallback((): void => {
    if (isGuestCheckoutEnabled && userIsGuest && orderItems?.length > 0) {
      dispatch(setCanGuestUserCheckout(true))
    } else {
      dispatch(setCanGuestUserCheckout(false))
    }
  }, [userIsGuest, orderItems, isGuestCheckoutEnabled])

  useEffect(() => {
    handleGuestCheckoutStatus()
  }, [handleGuestCheckoutStatus])

  return {
    canGuestUserCheckout,
  }
}
