import { getPreviewSelector } from '@features/preview/selector'
import { IFRAME_RESIZER } from '@foundation/constants/csr'
import { seoDataSelector } from '@features/seo/selector'

import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const BaseLayoutHead: React.FC = () => {
  const { t } = useTranslation()
  const getPreview = useSelector(getPreviewSelector)
  const seoData = useSelector(seoDataSelector)
  const [isIframeResizer, setIsIframeResizer] = useState(false)

  useEffect(() => {
    if (window[IFRAME_RESIZER]) {
      setIsIframeResizer(true)
    }
  }, [])

  return (
    <Head>
      <title key="title">{seoData?.page.title || t('CommerceEnvironment.Title')}</title>
      <meta name="description" content={seoData?.page.metaDescription || t('CommerceEnvironment.Description')} />
      {isIframeResizer && <script src="/iframeResizer.contentWindow.min.js" type="text/javascript" async />}
      {getPreview?.isEnabled && (
        <>
          <html lang={`${getPreview.locale}`} dir="ltr" />
          <title data-cm-metadata='[{"_":"properties.htmlTitle"}]'>{`${getPreview.name || 'CMS Preview'}`}</title>
          <meta name="generator" content="CoreMedia Content Cloud" />
          <script>
            {(document.documentElement.className = document.documentElement.className.replace(/no-js/g, 'js'))}
          </script>
          <link rel="stylesheet" href="/cms-preview/coremedia.preview.blueprint.css" />
          <script src="/cms-preview/jquery-3.6.0.min.js" />
          <script src="/cms-preview/coremedia.preview.js" />
          <script src="/cms-preview/coremedia.preview.$.js" />
          <script src="/cms-preview/coremedia.preview.blueprint.js" />
        </>
      )}
    </Head>
  )
}
