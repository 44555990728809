import config from '@configs/index'
import { StyledLogoWrapper } from './Logo.style'
import { LOGOS_MAP } from './constants'

const supportedLocales = config.availableLocales || []

const supportedLocaleMap = {
  'be-be': 'BE',
  'es-es': 'ES',
  'fr-fr': 'FR',
  'fr-be': 'BE',
  'en-ie': 'IE',
  'it-it': 'IT',
  'nl-nl': 'NL',
  'nl-be': 'BE',
  'en-uk': 'GB',
}

export type LogoProps = {
  locale?: (typeof supportedLocales)[number]
  variant?: 'white'
  className?: string
}

function Logo(props: LogoProps) {
  const { locale, variant, className } = props
  const mappedLocale = locale ? supportedLocaleMap[locale] : null
  const store = process.env.NEXT_PUBLIC_STORENAME || 'visiondirect'

  return (
    <StyledLogoWrapper className={`${className}`} data-variant={variant} data-locale={mappedLocale}>
      {LOGOS_MAP[store](mappedLocale)}
    </StyledLogoWrapper>
  )
}
export default Logo
