import styled from '@mui/material/styles/styled'

export const AppWrapper = styled('div', {
  name: 'BaseLayout',
  slot: 'AppWrapper',
  shouldForwardProp: prop => prop !== 'isPreview',
})<{ isPreview?: boolean }>(({ isPreview, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: isPreview ? '' : 'fill-available',
  minHeight: 'fill-available',
  maxHeight: isPreview ? '' : 'fill-available',
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up('sm')]: {
    height: isPreview ? '' : '100%',
    minHeight: '100vh',
    maxHeight: isPreview ? '' : '100vh',
  },
}))

export const AppMain = styled('main', {
  name: 'BaseLayout',
  slot: 'Main',
  shouldForwardProp: prop => prop !== 'openElements' && prop !== 'contactLensesPage',
})<{ openElements?: boolean; contactLensesPage: boolean }>(({ openElements, theme, contactLensesPage }) => ({
  backgroundColor: theme.palette.neutral.tones[100],
  maxWidth: '100vw',
  overflow: openElements ? 'hidden' : 'hidden auto',
  flex: 1,
  '&.is-pdp [data-element-id="X_X_Copyright"]': {
    marginBottom: contactLensesPage ? 0 : '88px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: contactLensesPage ? 0 : '48px',
    },
  },
  '&.is-cart [data-element-id="Footer"]': {
    marginBottom: '130px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
}))
